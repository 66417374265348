import React, { Component } from 'react';
import Firebase from '../api/Firebase';
import Contact from './Contact';

class Contacts extends Component {

  constructor() {
    super();

    this.state = {
      contacts: [],
      limit: 9999
    }

    this.next = null;
    this.loadMore = this.loadMore.bind(this);
  }
  componentDidMount() {

    this.next = Firebase.firestore().collection('contacts')
    .where('uid', '==', Firebase.auth().currentUser.uid)
    .orderBy('dateCreated', 'desc')
    .limit(this.state.limit)

    this.next.onSnapshot((doc) => {
      if(doc.size) {
        this.setState({
          contacts: doc.docs
        });
      }
    });
  }

  loadMore() {
    const lastVisible = this.state.contacts[this.state.contacts.length-1];
    // console.log(this.next, lastVisible.data());
    this.next.startAfter(lastVisible).onSnapshot((doc) => {
      if(doc.size) {
        this.setState({
          contacts: [...this.state.contacts, ...doc.docs]
        })
      }
    });
  }
  
  render() {
    return (
      <div className="col clients">
        {this.state.contacts.map((contact, index) => {
          return <Contact key={contact.id} index={index} contact={contact} templates={this.props.templates}/>
        })}
        {this.state.contacts.length >= this.state.limit ? (<button onClick={this.loadMore} className="btn pt-3 pb-3 btn-block bg-transparent">Laad meer...</button>) : (null)}
      </div>
    )
  }

}

export default Contacts

/*
<Contact index={2} status="signed"/>
<Contact index={3} status="signed"/>
<Contact index={4} status="sent"/>
<Contact index={5} status="sent"/>
<Contact index={6} status="sent"/>
<Contact index={7} status="late"/>
<Contact index={8} status="late"/>
<Contact index={9} status="late"/>
<Contact index={10} status="signed"/>
<Contact index={11} status="signed"/>
<Contact index={12} status="signed"/>
<Contact index={13} status="signed"/>
*/