import React, { Component } from 'react';
import ModalContactCreate from './ModalContactCreate';

class Aside extends Component {

  constructor(props) {
    
    super();

    this.state = {
      modalVisible: false
    }

    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal(e) {
    document.querySelector('body').classList.remove('modal-open');

    this.setState({
      modalVisible: !this.state.modalVisible
    });
  }
  
  render() {
    return (
      <div className="col-md-3 filter">
        <button onClick={this.toggleModal} className="btn btn-block btn-primary mb-4 text-truncate">Nieuw klant toevoegen</button>
        <div className="mb-3 d-none d-md-block">
          <h5 className="mb-2">Filter status</h5>
          <label className="d-block mb-0"><input className="mr-1" type="checkbox"/> In afwachting <span className="text-muted">(3)</span></label>
          <label className="d-block mb-0"><input className="mr-1" type="checkbox"/> Getekend <span className="text-muted">(8)</span></label>
          <label className="d-block mb-0"><input className="mr-1" type="checkbox"/> Verlopen <span className="text-muted">(2)</span></label>
        </div>
        {this.state.modalVisible && <ModalContactCreate index={this.props.index} status={this.state.status} closeModal={this.toggleModal}/>}
      </div>
    )
  }

}

export default Aside