import React, { Component } from 'react';
import Firebase from '../api/Firebase';
import History from './History';
import ContractsHorizontalListing from './ContractsHorizontalListing';
import md5 from 'md5';
import Moment from 'moment';
import 'moment/locale/nl';
import * as Icon from 'react-feather';

Moment.locale('nl');

class ModalContact extends Component {

  constructor(props) {
    super();

    this.state = {
      contact: props.contact,
      contractUrl: `https://docs.klantcontract.nl/?id=${props.contact.data().metaId}`,
      meta: false,
    }

    this.messageRef = React.createRef();
    this.metaRef = null;
    this.closeModal = this.closeModal.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
  }

  textareaFocussed() {
    window.onbeforeunload = () => {
      return 'leave?';
    };
  }

  componentWillUnmount() {
    this.metaRef();
    
    window.onbeforeunload = () => {
      return;
    };

    document.removeEventListener('keydown', this.closeModal);
    document.querySelector('body').classList.remove('modal-open');
  }

  componentDidMount() {

    document.addEventListener('keydown', this.closeModal);
    document.querySelector('body').classList.add('modal-open');

    this.metaRef = Firebase.firestore().collection('meta')
    .where('contactId', '==', this.props.contact.id)
    .limit(1)
    .onSnapshot((doc) => {
      // console.log(doc);
      if(doc.size) {
        this.setState({
          meta: doc.docs[0]
        });
      }
    });
  }

  closeModal(e) {
    if(!e.keyCode || e.keyCode === 27) {
      this.props.closeModal(e);
    }
  }

  sendEmail() {

    if(!window.confirm(`Contract e-mail naar ${this.state.contact.data().email} verzenden?`)) {
      return false;
    }

    const metaPromise = Firebase.firestore().collection('meta').where('contactId', '==', this.state.contact.id).limit(1).get();
    const contactPromise = Firebase.firestore().collection('contacts').doc(this.state.contact.id);
    const contractEmailMessage = this.messageRef.current.value;

    console.log(contractEmailMessage);

    return false;

    Promise.all([ metaPromise, contactPromise ])
    .then((values) => {

      const metaRef = values[0];
      const contactRef = values[1];
      const date = new Date();

      metaRef.docs[0].ref.update({
        dateUpdated: date,
        locked: false,
        templateId: this.state.meta.data().templateId
      });

      contactRef.update({
        dateUpdated: date,
        status: 'sent',
        contractEmailMessage: contractEmailMessage
      });
    })
    .catch((err) => {
      console.log(err);
    });
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.contact.data().dateUpdated !== prevState.contact.data().dateUpdated) {
      return {
        contact: nextProps.contact
      }
    }

    return null;
  }
  
  render() {

    const contact = this.state.contact.data();

    let tableRows = '';

    if(contact.meta) {
      tableRows = contact.meta.map((field, index) => {
        return(
          <span>
            <dt>{field.label}</dt>
            <dd>{field.value}</dd>
          </span>
        )
      });
    }

    return (
      <div>
        <div className="modal show fade" style={{ display: 'block' }} id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div className="modal-content shadow-lg border-0 pl-md-3 pr-md-3">
              <div className="modal-header">
                <h6 className="modal-title">{contact.fullName ? contact.fullName : 'Onbekend'}</h6>
                <button type="button" onClick={this.closeModal} className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body pt-4 pb-4">
                <div className="row">
                  <div className="col-md-3 text-left text-md-center">
                    <span className="avatar d-none d-md-inline-block avatar-lg mb-3">
                      <img alt="" src={`https://www.gravatar.com/avatar/${md5(contact.email)}?s=90&d=blank`} className="rounded-circle" width={90} height={90}/>
                      <em>{contact.fullName.charAt(0)}</em>
                    </span>
                    {contact.templateTitle && <p><strong className="d-block">{contact.templateTitle}</strong></p>}
                  </div>
                  <div className="col-md-9">
                    {contact.status === 'sent' && <p className="border rounded bg-light p-3 d-block">Contractformulier is verstuurd, wachtend op ondertekening.</p>}
                    {contact.templateId && contact.status === 'new' && <p className="border rounded bg-light p-3 d-block">Verstuur het contract voor ondertekening. Controleer het <a target="_blank" rel="noopener noreferrer" href={`${this.state.contractUrl}&preview=true`}>contractformulier</a>.</p>}
                    {contact.templateId && contact.status === 'new' && 
                      <div>
                        <textarea required={true} onFocus={this.textareaFocussed} ref={this.messageRef} rows={8} className="form-control mb-2" defaultValue={`${contact.fullName}, 

Hierbij het linkje naar (${contact.templateTitle}) contract. Ik kijk er naar uit met jullie aan de slag te gaan.
[-link_naar_het_contract-]

Groetjes,
Brenda`}></textarea>
                        <p><button onClick={this.sendEmail} className="btn btn-primary">Stuur bericht</button></p>
                      </div>
                    }
                    {contact.status === 'signed' && <p className="border rounded bg-light p-3 d-block">Contract getekend op: {Moment(contact.dateUpdated, 'X').format('D MMMM YYYY [om] H:mm')}</p>}
                    {!contact.templateTitle && <ContractsHorizontalListing templates={this.props.templates} contact={this.state.contact}/>}

                    {contact.meta &&
                    
                        <dl>
                          {tableRows}
                        </dl>
                    
                    }
                    <History contact={this.state.contact}/>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <a href="!#" className="text-danger">Verwijder</a>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show"></div>
      </div>
    )
  }

}

export default ModalContact;