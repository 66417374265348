import React, { Component } from 'react';
import Firebase from '../api/Firebase';
import * as Icon from 'react-feather';

class Header extends Component {

  constructor() {

    super();

    this.state = {
      contactsCount: 0
    }
  }

  componentDidMount() {
    Firebase.firestore().collection('counts').doc(Firebase.auth().currentUser.uid)
    .onSnapshot((docs) => {
      if(docs.exists) {
        this.setState({
          contactsCount: docs.data().contactsCount
        });
      }
    });
  }

  createTemplate(e) {
    e.preventDefault();

    let title = window.prompt('Geef contract een naam');

    if(!title) {
      return false;
    }

    Firebase.firestore().collection('templates').add({
      title: title,
      dateCreated: new Date(),
      dateUpdated: new Date(),
      fields: [
        {
          required: true,
          name: 'birthday',
          type: 'date',
          label: 'Geboortedatum',
        },
        {
          required: true,
          name: 'kids_ages',
          type: 'text',
          label: 'Eventuele leeftijd van de kinderen',
        },
        {
          required: false,
          name: 'prefer',
          type: 'text',
          label: 'Voorkeur voor stijl/ kleur gebruik tijdens de fotoshoot',
        },
        {
          required: false,
          name: 'referer',
          type: 'select',
          label: 'Hoe bent u op deze site terrect gekomen?',
          options: [
            'Google',
            'Vriend(in)',
            'Bladreclame'
          ]
        },
        {
          required: false,
          name: 'messages',
          type: 'textarea',
          label: 'Opmerkingen',
        }
      ],
      uid: Firebase.auth().currentUser.uid,
    })
    .then(() => {
      alert('Template created...');
    });
  }
  
  render() {
    const templates = this.props.templates.map((template, index) => {
      return <li key={template.id}><a href="!#">{template.data().title}</a></li>
    });

    return (
      <header className="header">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <span className="mr-2 mr-md-4"><Icon.Server color="#fff" size={16}/></span>
              <ul className="list-inline d-none d-md-inline">
                <li className="list-inline-item mr-2 mr-md-3">
                  <a href="!#">Klanten ({this.state.contactsCount})</a>
                </li>
                <li className="list-inline-item mr-2 mr-md-3">
                  <a href="!#">Contracten</a>
                  <ul className="rounded-bottom">
                    {templates}
                    <li><a onClick={this.createTemplate} href="!#">+ Nieuw contract</a></li>
                  </ul>
                </li>
              </ul>
              <span className="float-right">
                <span className="mr-2 mr-md-3"><Icon.Bell color="#fff" size={16}/></span>
                <span><Icon.Settings color="#fff" size={16}/></span>
              </span>
            </div>
          </div>
        </div>
      </header>
    )
  }

}

export default Header