import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/database';

const config = {
  apiKey: "AIzaSyCo-FAc3_m41u3t7pCPpFQExhDnP5GLa08",
  authDomain: "klantcontract-aac74.firebaseapp.com",
  databaseURL: "https://klantcontract-aac74.firebaseio.com",
  projectId: "klantcontract-aac74",
  storageBucket: "klantcontract-aac74.appspot.com",
  messagingSenderId: "732815365597"
};

const Firebase = firebase.initializeApp(config);

export default Firebase;