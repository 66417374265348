import React, { Component } from 'react';
import md5 from 'md5';
import ModalContact from './ModalContact';
import Moment from 'moment';
import 'moment/locale/nl';

Moment.locale('nl');

class Contact extends Component {

  constructor(props) {
    
    super();

    this.state = {
      modalVisible: false,
      status: props.status,
      statusText: '',
      contact: props.contact,
      meta: null
    }

    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidMount() {
    
  }

  toggleModal(e) {
    this.setState({
      modalVisible: !this.state.modalVisible
    });
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.contact.data().dateUpdated !== prevState.contact.data().dateUpdated) {
      // console.log(`contact updated... with new props value: ${nextProps.contact.data().dateUpdated}, ${prevState.contact.data().dateUpdated}`);
      return {
        contact: nextProps.contact
      }
    }

    if(nextProps.meta && nextProps.meta && nextProps.meta.data().dateUpdated !== prevState.meta.data().dateUpdated) {
      // console.log(`meta updated... with new props value: ${nextProps.meta.data().dateUpdated}, ${prevState.meta.data().dateUpdated}`);
      return {
        meta: nextProps.meta
      }
    }

    return null;
  }
  
  render() {

    const contact = this.state.contact.data();

    let statusText = '';

    switch(contact.status) {
      case 'sent':
        statusText = 'In afwachting';
      break;
      case 'signed':
        statusText = 'Getekend';
      break;
      case 'late':
        statusText = 'Verlopen';
      break;
      default:
        statusText = 'Nieuw';
      break;
    }

    return (
      <div data-selected={this.state.modalVisible} data-status={contact.status}>
        <div className="clientHandle" onClick={this.toggleModal}>
          <div className={`rounded client p-3 mb-2 d-flex justify-content-between align-items-center`}>
            <span className="avatar mr-4 d-none d-md-block">
              <img alt="" src={`https://www.gravatar.com/avatar/${md5(contact.email)}?s=45&d=blank`} className="rounded-circle" width={45} height={45}/>
              <em>{contact.fullName.charAt(0)}</em>
            </span>
            <span className="description text-truncate">
              <h6 className="mb-0">{contact.fullName ? contact.fullName : 'Onbekend'}</h6>
              <small>{contact.email}</small>
            </span>
            <span className="contract d-none d-lg-block">
              {contact.templateTitle}
            </span>
            <span className="ml-auto text-right">
              <span className="mr-3 d-none d-md-inline-block text-muted small">{Moment(contact.dateCreated, 'X').fromNow()}</span>
              <span className={`btn-${contact.status} btn-status btn`}><span className="d-none d-md-inline-block">{statusText}</span></span>
            </span>
          </div>
        </div>
        {this.state.modalVisible && <ModalContact index={this.props.index} templates={this.props.templates} contact={this.state.contact} closeModal={this.toggleModal}/>}
      </div>
    )
  }

}

export default Contact;