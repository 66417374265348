import React, { Component } from 'react';
import * as Icon from 'react-feather';

class Top extends Component {
  
  render() {
    return (
      <section className="section top">
        <div className="container">
          <div className="row">
            <div className="col d-none d-md-flex align-items-start">
              <a className="d-none d-lg-block btn bg-white mr-1" href="!#" role="button"><Icon.ChevronLeft color="#333" size={16}/></a>
              <a className="d-none d-lg-block btn bg-white mr-3" href="!#" role="button"><Icon.ChevronRight color="#333" size={16}/></a>
              <h3>1 december - 31 december 2018</h3>
            </div>
            <div className="col">
              <div className="row">
                <div className="col-4 text-right d-none d-lg-block pr-0">
                  <div className="dropdown _show">
                    <a className="btn bg-white text-secondary dropdown-toggle" href="!#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Maand
                    </a>
                    <div className="dropdown-menu dropdown-menu-right _show" aria-labelledby="dropdownMenuLink">
                      <a className="dropdown-item" href="!#">Week</a>
                      <a className="dropdown-item" href="!#">Month</a>
                      <a className="dropdown-item" href="!#">Quarter</a>
                      <a className="dropdown-item" href="!#">Year</a>
                      <a className="dropdown-item" href="!#">All Time</a>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="input-group search">
                    <input type="text" className="form-control" placeholder="Zoeken op naam, e-mail" aria-label="" aria-describedby=""/>
                    <div className="input-group-append">
                      <button className="btn bg-white" type="button" id="button-addon2"><Icon.Search color="#333" size={16}/></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }

}

export default Top