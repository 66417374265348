import React, { Component } from 'react';
import Firebase from '../api/Firebase';
import Moment from 'moment';

class ContractsHorizontalListing extends Component {

  constructor(props) {
    super();

    this.state = {
      templates: props.templates
    }

    this.templatesRef = null;
    this.attachTemplate = this.attachTemplate.bind(this);
  }

  attachTemplate(e, template) {
    e.preventDefault();

    if(!window.confirm(`Deze contract koppelen?`)) {
      return false;
    }

    const date = new Date();
    const templateId = template.id;
    const templateTitle = template.data().title;
    const contactId = this.props.contact.id;
    const metaId = this.props.contact.data().metaId;

    const contactPromise = Firebase.firestore().collection('contacts').doc(contactId).update({
      templateId: templateId,
      templateTitle: templateTitle,
      dateUpdated: date
    });

    const metaPromise = Firebase.firestore().collection('meta').doc(metaId).update({
      templateId: templateId,
      templateTitle: templateTitle,
      dateUpdated: date
    });

    Promise.all([ contactPromise, metaPromise ])
    .then((values) => {
      Firebase.firestore().collection('logs').add({
        contactId: contactId,
        dateCreated: date,
        description: `Gekoppeld aan contract: ${templateTitle}`
      });
    });
  }

  componentDidMount() {
    this.templatesRef = Firebase.firestore().collection('templates').onSnapshot((querySnapshot) => {
      this.setState({
        templates: querySnapshot.docs
      });
    });
  }

  componentWillUnmount() {
    this.templatesRef();
  }
  
  render() {
    if(!this.state.templates.length) {
      return null;
    }

    const templates = this.state.templates.map((template, index) => {
      return(
        <a href="!#" key={index} onClick={(e) => this.attachTemplate(e, template)} className="contract list-group-item list-group-item-action d-flex justify-content-between align-items-center">
          {template.data().title}
        </a>
      )
    });

    return (
      <div>
        <p className="border rounded bg-light p-3 d-block">Kies het contract voor deze klant.</p>
        <div className="contracts list-group">
          {templates}
        </div>
      </div>
    )
  }

}

export default ContractsHorizontalListing;