import React, { Component } from 'react';
import Header from './components/Header';
import Firebase from './api/Firebase';
import Top from './components/Top';
import Contacts from './components/Contacts';
import Aside from './components/Aside';
import Loading from './loading.gif';
import * as Icon from 'react-feather';

import './App.css';

Firebase.firestore().settings({ timestampsInSnapshots: true });

class App extends Component {

  constructor() {
    super();

    this.state = {
      formSubmitting: false,
      user: null,
      loginForm: false,
      templates: [],
      email: '',
      password: ''
    }

    this.signIn = this.signIn.bind(this);
  }

  componentDidMount() {

    Firebase.auth().onAuthStateChanged((user) => {
      if(user) {
        Firebase.firestore().collection('templates').get()
        .then((querySnapshot) => {
          this.setState({
            templates: querySnapshot.docs,
            user: user,
            loginForm: false,
            formSubmitting: false
          });
        });
      }
      else {
        this.setState({
          loginForm: true
        });
      }

    });
  }

  signIn(e) {
    e.preventDefault();

    this.setState({
      formSubmitting: true
    });

    Firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password)
    .catch((error) => {
      
      alert(error.message);

      this.setState({
        formSubmitting: false
      });
    });
  }

  setEmail(e) {
    this.setState({
      email: e.target.value
    })
  }

  setPassword(e) {
    this.setState({
      password: e.target.value
    })
  }

  render() {

    if(this.state.loginForm) {
      return (
        <div className="App text-center login mx-auto">
          <p className="lead"><Icon.Server color="#333" size={22}/><br/>Klantcontract</p>
          <form onSubmit={this.signIn}>
            <div className="mb-2">
              <input className="form-control" required={true} name="email" placeholder="E-mail" onChange={this.setEmail.bind(this)} type="email"/>
            </div>
            <div className="mb-2">
              <input className="form-control" required={true} name="password" placeholder="Wachtwoord" onChange={this.setPassword.bind(this)} type="password"/>
            </div>
            <button disabled={this.state.formSubmitting} className="mb-3 btn btn-primary btn-block" type="submit">{this.state.formSubmitting ? 'Inloggen...' : 'Login'}</button>
            <small>© 2018</small>
          </form>
        </div>
      );
    }

    if(!this.state.user) {
      return <div className="App loading"><img src={Loading} width={42} alt=""/></div>;
    }

    return (
      <div className="App">
        <Header templates={this.state.templates}/>
        <Top/>
        <section className="section main mb-2 mb-md-4">
          <div className="container">
            <div className="row">
              <Aside/>
              <Contacts templates={this.state.templates}/>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default App;
