import React, { Component } from 'react';
import Firebase from '../api/Firebase';
import Moment from 'moment';

class History extends Component {

  constructor() {

    super();

    this.state = {
      hidden: true,
      docs: []
    };

    this.historyRef = null;
  }
  
  componentDidMount() {
    this.historyRef = Firebase.firestore().collection('logs')
    .where('contactId', '==', this.props.contact.id)
    .orderBy('dateCreated', 'desc')
    .onSnapshot((doc) => {
      this.setState({
        docs: doc.docs
      });
    });
  }

  componentWillUnmount() {
    this.historyRef();
  }

  render() {
    const items = this.state.docs.map((doc, index) => {
      return(
        <li data-status={doc.data().status} key={doc.id}>
          <small className="d-block">{Moment(doc.data().dateCreated, 'X').format('D MMMM YYYY - H:mm')}</small>
          {doc.data().description}
        </li>
      )
    })
    return (
      <div className="mt-3">
        <p className="mb-2"><a href="!#" onClick={(e) => { e.preventDefault(); this.setState({ hidden: !this.state.hidden }); }}><strong>Bekijk activiteiten</strong></a> <span className="text-muted small d-none d-md-inline-block">{Moment(this.props.contact.data().dateUpdated, 'X').from()}</span></p>
        {!this.state.hidden &&
        <ul className="history">
          {items}
        </ul>}
      </div>
    )
  }

}

export default History;