import React, { Component } from 'react';
import Firebase from '../api/Firebase';
import Moment from 'moment';
import 'moment/locale/nl';

Moment.locale('nl');

class ModalContactCreate extends Component {

  constructor() {
    super();

    this.state = {
      email: '',
      fullName: 'Onbekend'
    }

    this.closeModal = this.closeModal.bind(this);
    this.createContact = this.createContact.bind(this);
    this.setEmail = this.setEmail.bind(this);
    this.setFullName = this.setFullName.bind(this);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.closeModal);
    document.querySelector('body').classList.remove('modal-open');
  }

  componentDidMount() {
    document.addEventListener('keydown', this.closeModal);
    document.querySelector('body').classList.add('modal-open');
  }

  createContact(e) {
    e.preventDefault();

    this.props.closeModal(e);

    const date = new Date();
    const contact = Firebase.firestore().collection('contacts').doc();
    const meta = Firebase.firestore().collection('meta').doc();

    // creating contact
    const contactPromise = Firebase.firestore().collection('contacts').doc(contact.id).set({
      dateCreated: date,
      dateUpdated: date,
      status: 'new',
      email: this.state.email,
      fullName: this.state.fullName,
      uid: Firebase.auth().currentUser.uid,
      metaId: meta.id
    })

    const metaPromise = Firebase.firestore().collection('meta').doc(meta.id).set({
      locked: true,
      dateCreated: date,
      dateUpdated: date,
      dateExpired: Moment().add(1, 'month').toDate(),
      contactId: contact.id,
      email: this.state.email,
      fullName: this.state.fullName
    });

    Promise.all([ contactPromise, metaPromise ])
    .then((values) => {
      Firebase.firestore().collection('logs').add({
        contactId: contact.id,
        dateCreated: date,
        description: `Contact aangemaakt`
      });
    })
    .catch((err) => {
      console.error(err);
    })
  }

  closeModal(e) {
    if(!e.keyCode || e.keyCode === 27) {
      this.props.closeModal(e);
    }
  }

  setEmail(e) {
    this.setState({
      email: e.target.value
    })
  }

  setFullName(e) {
    this.setState({
      fullName: e.target.value
    })
  }
  
  render() {
    return (
      <div>
        <div className="modal show fade" style={{ display: 'block' }} id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content shadow-lg border-0 pl-md-3 pr-md-3">
              <div className="modal-header">
                <h5 className="modal-title">Contact toevoegen</h5>
                <button type="button" onClick={this.closeModal} className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body pb-4">
                <div className="row">
                  <div className="col">
                    <form onSubmit={this.createContact}>
                      <div className="form-group">
                        <label>Volledige naam</label>
                        <input autoFocus={true} onChange={this.setFullName} type="text" className="form-control"/>
                      </div>
                      <div className="form-group">
                        <label>E-mail *</label>
                        <input required={true} onChange={this.setEmail} type="email" className="form-control"/>
                      </div>
                      <div className="form-group">
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox"/>
                          <label className="form-check-label">
                            Ik heb toestemming gekregen om deze persoon toe te voegen
                          </label>
                        </div>
                      </div>
                      <button type="submit" className="btn btn-success">Toevoegen</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show"></div>
      </div>
    )
  }

}

export default ModalContactCreate;